import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ApiKeysService } from "../api-keys.service";
import { ApiKey } from "../../../../models/shared";
import { TitleService } from "../../../../services/title.service";

@Component({
    selector: "app-api-key-form",
    templateUrl: "./api-key-form.component.html"
})
export class ApiKeyFormComponent implements OnInit, OnDestroy {
    apiKey: ApiKey;
    apiKeyId: string;
    apiKeyNames: string[];
    action: string;
    isReadOnly = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;

    loading = true;
    saving = false;
    constants = Constants;

    private apiKeysSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: ApiKeysService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.apiKeyId = params.get("id");
            this.action = params.get("action");
            if (this.apiKeyId) {
                this.ts.refreshApiKeys();

                this.ts.apiKeys.pipe(take(1)).subscribe(apiKeys => {
                    this.apiKey = Object.assign(
                        {},
                        apiKeys.find(t => t.id === this.apiKeyId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.apiKey && !this.isClone && !this.isEdit) {
            this.apiKey = new ApiKey();
            this.apiKey.name = "";
        }

        // Set Title
        this.titleService.setTitle("API_KEY", this.action, this.apiKey);
    }

    ngOnInit() {
        this.ts.refreshApiKeys();

        this.apiKeysSubscription = this.ts.apiKeys.subscribe((apiKeys: ApiKey[]) => {
            this.apiKeyNames = apiKeys.map(t => t.name);
            if (this.isEdit) this.apiKeyNames = apiKeys.filter(t => t.id !== this.apiKeyId).map(t => t.name);
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.apiKeysSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model: ApiKey = {
            name: this.apiKey.name,
            is_read_only: this.isReadOnly
        };

        const result = await this.ts.addApiKey(model);
        if (result !== false) {
            this.saving = false;
            this.router.navigate([Constants.urls.configuration.apiKeys]);
        } else this.saving = false;
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.apiKeys]);
    }
}
